.sonata-preview-form-container {
    .row {
        display: none;
    }
}

* {
    outline: none !important;
}

a {
    text-decoration: none !important;
    -webkit-transition: 0.4s;
    transition: 0.4s;
    color: inherit;
}

body {
    background-color: #d7dfe2;
    font-family: 'Lato', sans-serif !important;
    -webkit-font-smoothing: antialiased;

    .section--inbox {
        .pad__polling {
            .item {
                box-shadow: none;
                padding: 20px 20px;
                border-radius: 4px;

                .ui-radio {
                    &:checked + label + .item__bg {
                        position: unset;
                    }
                }
            }

            .btn-square {
                font-size: 18px;
                border-radius: 4px;
                padding: 10px 25px;
                background-color: #4CE0B3;
                color: #fff;
            }

            background-color: #e8eef1;
        }

        .pad__content {
            .box-avatar {
                border: none;
            }

            .box-content {
                strong {
                    color: #4CE0B3;
                }
            }
        }

        .pad__header {
            background-color: #4CE0B3 !important;
        }
    }
}

body.login-page {
    background-color: #d7dfe2;
    background-image: linear-gradient(0deg, rgba(215, 223, 226, 0.1) 0%, rgba(215, 223, 226, 1) 150%), url('../images/landing.jpg') !important;
    background-size: contain;
    background-position: 50% bottom;
    background-repeat: no-repeat;
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;

    .alert {
        position: fixed;
        z-index: 99;
        right: 40px;
        top: 100px;
    }
}

.section--inbox {
    background: none !important;
}

.login-header {
    display: flex;
    margin-bottom: auto;
    position: absolute;
    top: 0;
    width: 100%;
    padding: 18px 15px;
    align-items: center;
    justify-content: space-between;

    nav {
        .nav-link {
            color: #3d424c !important;
            font-size: 16px;
            font-weight: 500;
            line-height: 24px;
            padding-top: 18px !important;
            padding-bottom: 18px !important;
            padding-left: 18px !important;
            padding-right: 18px !important;
            display: block;

            &:not(.button) {
                &:hover {
                    color: #000000 !important;
                }
            }
        }

        .nav-link.button {
            &:hover {
                background-color: #4edeb3 !important;
                color: #fff !important;
            }

            &:focus {
                background-color: #4edeb3 !important;
                color: #fff !important;
            }

            background-color: #fff;
            border-radius: 50px;
            padding-top: 10px !important;
            padding-bottom: 10px !important;
            margin-top: 8px !important;
            margin-bottom: 8px !important;
        }

        ul {
            display: -ms-flexbox;
            display: flex;
            flex-direction: row;
            padding-left: 0;
            margin-bottom: 0;
            list-style: none;
        }
    }
}

.login-logo {
    img {
        height: 40px;
    }
}

.login-block {
    background-color: #fff;
    padding: 65px 50px;
    max-width: 500px;
    position: relative;

    .form-login {
        padding: 0;
        margin-top: 44px;

        input[type=email] {
            border: solid 1px #DDDEE0 !important;
            border-radius: 4px;
            background: #fff;
            display: block;
            width: 100%;
            padding: 10px 12px !important;
            font-size: 16px;
            line-height: 1 !important;
            color: #3D424C;
            background-color: #fff;
            background-image: none;
            flex: 1 0 100%;
        }

        input[type=checkbox] {
            border-color: #4edeb3;
            border-color: #4edeb3;
        }

        button {
            margin-top: 30px;
            font-size: 16px;
            text-transform: inherit;
            padding: 11px;
            border-radius: 4px;
            background-color: #4edeb3;
            max-width: 310px;
            transition: all 0.3s ease-in-out;

            &:hover {
                background-color: #2dd09f;
            }

            &:focus {
                background-color: #2dd09f;
            }
        }
    }

    .login-title {
        font-size: 33px;
        font-weight: bold;
        color: #3D424C;
        margin: 0;
    }

    .form-control {
        height: auto;
        border: none !important;
        box-shadow: none !important;
        -webkit-box-shadow: none !important;
        -moz-box-shadow: none !important;
        margin-top: 12px;
        font-size: 16px !important;
        font-weight: 400;
        padding: 0;
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        width: 310px;

        &:first-child {
            margin-top: 0;
        }

        input {
            margin-top: 5px;
        }
    }
}

.form-login {
    input[type=password] {
        border: solid 1px #DDDEE0 !important;
        border-radius: 4px;
        background: #fff;
        display: block;
        width: 100%;
        padding: 10px 12px !important;
        font-size: 16px;
        line-height: 1 !important;
        color: #3D424C;
        background-color: #fff;
        background-image: none;
        flex: 1 0 100%;
    }
}

.container-custom {
    position: relative;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
}

@media (max-width: 767px) {
    .login-header {
        nav {
            ul {
                .nav-link.button {
                    display: none;
                }
            }
        }
    }
}

@media (max-width: 450px) {
    .login-block {
        .form-control {
            max-width: 310px;
            width: auto;
        }

        padding: 45px 40px;
    }
}

@media (min-width: 576px) {
    .container-custom {
        max-width: 540px;
    }
}

@media (min-width: 768px) {
    .container-custom {
        max-width: 720px;
    }
}

@media (min-width: 992px) {
    .container-custom {
        max-width: 960px;
    }
}

@media (min-width: 1200px) {
    .container-custom {
        max-width: 1140px;
    }
}

@media (min-width: 1300px) {
    .container-custom {
        max-width: 1340px;
    }
}
